import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
// import { HttpInterceptorService } from "./http-interceptor.service";
import {
  HttpClientModule,
  HttpClient,
  HTTP_INTERCEPTORS,
} from "@angular/common/http";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from "@ngx-translate/core";
import {
  HttpInterceptorService,
  DEFAULT_TIMEOUT,
} from "./http-interceptor.service";

import { ToastrModule } from "ngx-toastr";
import { from, Observable } from "rxjs";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import {
  FormControl,
  FormsModule,
  NgControl,
  ReactiveFormsModule,
} from "@angular/forms";

import { AgmCoreModule } from "@agm/core";

export class WebpackTranslateLoader implements TranslateLoader {
  getTranslation(lang: string): Observable<any> {
    let langLocal = localStorage.getItem("lang");
    if (langLocal == undefined) {
      localStorage.setItem("lang", "en");
    } else {
      localStorage.setItem("lang", langLocal);
    }
    return from(import(`../assets/i18n/${localStorage.getItem("lang")}.json`));
  }
}
@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AgmCoreModule.forRoot({
      apiKey: "AIzaSyCj2EgsGnP7NMPgHenuaHyQk1VISmZo2yM",
      libraries: ["places"],
    }),
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        deps: [HttpClient],
        useClass: WebpackTranslateLoader,
      },
    }),
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    { provide: NgControl, useValue: new FormControl() },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true,
    },

    { provide: DEFAULT_TIMEOUT, useValue: 40000 },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
