import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

const routes: Routes = [
  {
    path: "Form",
    loadChildren: () => import("./form/form.module").then((m) => m.FormModule),
  },
  {
    path: "footer",
    loadChildren: () =>
      import("./footer/footer.module").then((m) => m.FooterModule),
  },
  {
    path: "",
    loadChildren: () =>
      import("./login/login.module").then((m) => m.LoginModule),
  },
  {
    path: "small-footer",
    loadChildren: () =>
      import("./small-footer/small-footer.module").then(
        (m) => m.SmallFooterModule
      ),
  },
  {
    path: "verification/:flow",
    loadChildren: () =>
      import("./verification/verification.module").then(
        (m) => m.VerificationModule
      ),
  },
  {
    path: "sign-in-pw",
    loadChildren: () =>
      import("./sign-in-pw/sign-in-pw.module").then((m) => m.SignInPwModule),
  },
  {
    path: "profile-navigation",
    loadChildren: () =>
      import("./profile-navigation/profile-navigation.module").then(
        (m) => m.ProfileNavigationModule
      ),
  },
  {
    path: "home",
    loadChildren: () =>
      import("./funds/funds.module").then((m) => m.FundsModule),
  },
  {
    path: "my-wallet",
    loadChildren: () =>
      import("./my-az-account/my-az-account.module").then(
        (m) => m.MyAzAccountModule
      ),
  },
  {
    path: "edit-profile",
    loadChildren: () =>
      import("./edit-profile/edit-profile.module").then(
        (m) => m.EditProfileModule
      ),
  },
  {
    path: "change-password",
    loadChildren: () =>
      import("./change-password/change-password.module").then(
        (m) => m.ChangePasswordModule
      ),
  },
  {
    path: "change-password-verification",
    loadChildren: () =>
      import(
        "./change-password-verification/change-password-verification.module"
      ).then((m) => m.ChangePasswordVerificationModule),
  },
  {
    path: "change-phone",
    loadChildren: () =>
      import("./change-phone/change-phone.module").then(
        (m) => m.ChangePhoneModule
      ),
  },
  {
    path: "password",
    loadChildren: () =>
      import("./password/password.module").then((m) => m.PasswordModule),
  },
  {
    path: "register",
    loadChildren: () =>
      import("./register/register.module").then((m) => m.RegisterModule),
  },
  {
    path: "fundDetails",
    loadChildren: () =>
      import("./fund-details/fund-details.module").then(
        (m) => m.FundDetailsModule
      ),
  },
  {
    path: "bank-accounts",
    loadChildren: () =>
      import("./bank-accounts/bank-accounts.module").then(
        (m) => m.BankAccountsModule
      ),
  },
  {
    path: "bank-account-details/:accountId",
    loadChildren: () =>
      import("./bank-account-details/bank-account-details.module").then(
        (m) => m.BankAccountDetailsModule
      ),
  },
  {
    path: "my-funds",
    loadChildren: () =>
      import("./my-funds/my-funds.module").then((m) => m.MyFundsModule),
  },
  {
    path: "accountSetUp",
    loadChildren: () =>
      import("./account-set-up/account-set-up.module").then(
        (m) => m.AccountSetUpModule
      ),
  },
  {
    path: "ID-pic",
    loadChildren: () =>
      import("./id-pic/id-pic.module").then((m) => m.IDPicModule),
  },
  {
    path: "client-pic",
    loadChildren: () =>
      import("./client-pic/client-pic.module").then((m) => m.ClientPicModule),
  },
  {
    path: "client-pic-two",
    loadChildren: () =>
      import("./client-pic-two/client-pic-two.module").then(
        (m) => m.ClientPicTwoModule
      ),
  },
  {
    path: "client-data",
    loadChildren: () =>
      import("./client-data/client-data.module").then(
        (m) => m.ClientDataModule
      ),
  },
  {
    path: "bank-references",
    loadChildren: () =>
      import("./bank-references/bank-references.module").then(
        (m) => m.BankReferencesModule
      ),
  },
  {
    path: "KYC/:page",
    loadChildren: () => import("./kyc/kyc.module").then((m) => m.KYCModule),
  },
  {
    path: "sign-contract/:type",
    loadChildren: () =>
      import("./sign-contract/sign-contract.module").then(
        (m) => m.SignContractModule
      ),
  },
  {
    path: "sign-contract-remotely",
    loadChildren: () =>
      import("./sign-contract-remotely/sign-contract-remotely.module").then(
        (m) => m.SignContractRemotelyModule
      ),
  },
  {
    path: "sign-contract-my-location",
    loadChildren: () =>
      import(
        "./sign-contract-my-location/sign-contract-my-location.module"
      ).then((m) => m.SignContractMyLocationModule),
  },
  {
    path: "sign-contract-place",
    loadChildren: () =>
      import("./sign-contract-place/sign-contract-place.module").then(
        (m) => m.SignContractPlaceModule
      ),
  },
  {
    path: "send-feedback",
    loadChildren: () =>
      import("./send-feedback/send-feedback.module").then(
        (m) => m.SendFeedbackModule
      ),
  },
  {
    path: "contact-to-team/:type",
    loadChildren: () =>
      import("./report-problem/report-problem.module").then(
        (m) => m.ReportProblemModule
      ),
  },
  {
    path: "contact-us",
    loadChildren: () =>
      import("./contact-us/contact-us.module").then((m) => m.ContactUsModule),
  },
  {
    path: "submitted-successfully",
    loadChildren: () =>
      import("./submitted-successfully/submitted-successfully.module").then(
        (m) => m.SubmittedSuccessfullyModule
      ),
  },
  {
    path: "portfolio",
    loadChildren: () =>
      import("./portfolio/portfolio.module").then((m) => m.PortfolioModule),
  },
  {
    path: "my-funds-details",
    loadChildren: () =>
      import("./my-funds-details/my-funds-details.module").then(
        (m) => m.MyFundsDetailsModule
      ),
  },
  { path: 'continue-SDK', loadChildren: () => import('./continue-sdk/continue-sdk.module').then(m => m.ContinueSDKModule) },
  { path: 'buy-fund/:flow', loadChildren: () => import('./buy-fund/buy-fund.module').then(m => m.BuyFundModule) },
  { path: 'add-bank', loadChildren: () => import('./add-bank/add-bank.module').then(m => m.AddBankModule) },
  { path: 'success-investment', loadChildren: () => import('./success-investment/success-investment.module').then(m => m.SuccessInvestmentModule) },
  { path: 'sell-fund', loadChildren: () => import('./sell-fund/sell-fund.module').then(m => m.SellFundModule) },
  { path: 'page-not-found', loadChildren: () => import('./page-not-found/page-not-found.module').then(m => m.PageNotFoundModule) },
  { path: 'injection', loadChildren: () => import('./injection/injection.module').then(m => m.InjectionModule) },
  { path: 'withdraw', loadChildren: () => import('./withdraw/withdraw.module').then(m => m.WithdrawModule) },
  { path: 'selectUser', loadChildren: () => import('./select-user/select-user.module').then(m => m.SelectUserModule) },
  { path: 'FAQ', loadChildren: () => import('./faq/faq.module').then(m => m.FAQModule) },
  { path: 'legal', loadChildren: () => import('./legal/legal.module').then(m => m.LegalModule) },
  { path: 'faq-details/:flow', loadChildren: () => import('./faq-details/faq-details.module').then(m => m.FaqDetailsModule) },
  { path: 'payment-status', loadChildren: () => import('./payment-stauts/payment-stauts.module').then(m => m.PaymentStautsModule) },
  { path: 'terms', loadChildren: () => import('./terms/terms.module').then(m => m.TermsModule) },
  { path: 'privacy', loadChildren: () => import('./privacy/privacy.module').then(m => m.PrivacyModule) },
  { path: 'user-signature', loadChildren: () => import('./user-signature/user-signature.module').then(m => m.UserSignatureModule) },
  { path: 'social-phone', loadChildren: () => import('./social-phone/social-phone.module').then(m => m.SocialPhoneModule) },
  { path: 'social-verify-phone', loadChildren: () => import('./social-verify-phone/social-verify-phone.module').then(m => m.SocialVerifyPhoneModule) },
  { path: 'login-slider', loadChildren: () => import('./login-slider/login-slider.module').then(m => m.LoginSliderModule) },
  { path: 'creat-pin', loadChildren: () => import('./creat-pin/creat-pin.module').then(m => m.CreatPinModule) },
  { path: 'enter-pin', loadChildren: () => import('./enter-pin/enter-pin.module').then(m => m.EnterPinModule) },
  { path: 'delete-account', loadChildren: () => import('./delete-account/delete-account.module').then(m => m.DeleteAccountModule) },
  { path: 'profile-info', loadChildren: () => import('./profile-info/profile-info.module').then(m => m.ProfileInfoModule) },
  { path: 'tips', loadChildren: () => import('./tips/tips.module').then(m => m.TipsModule) },
  { path: 'tip-details/:id', loadChildren: () => import('./tip-details/tip-details.module').then(m => m.TipDetailsModule) },
  { path: 'stocks', loadChildren: () => import('./stocks/stocks.module').then(m => m.StocksModule) },
  { path: 'stocks-details/:id', loadChildren: () => import('./stocks-details/stocks-details.module').then(m => m.StocksDetailsModule) },
  { path: 'invest', loadChildren: () => import('./invest/invest.module').then(m => m.InvestModule) },
  { path: 'my-stock-details', loadChildren: () => import('./my-stock-details/my-stock-details.module').then(m => m.MyStockDetailsModule) },
  { path: 'sell-stock', loadChildren: () => import('./sell-stock/sell-stock.module').then(m => m.SellStockModule) },
  { path: 'watchlist', loadChildren: () => import('./watchlist/watchlist.module').then(m => m.WatchlistModule) },
  { path: 'table', loadChildren: () => import('./table/table.module').then(m => m.TableModule) },
  { path: 'orders', loadChildren: () => import('./orders/orders.module').then(m => m.OrdersModule) },
  { path: 'alerts', loadChildren: () => import('./alerts/alerts.module').then(m => m.AlertsModule) },
  { path: 'user-account', loadChildren: () => import('./user-account/user-account.module').then(m => m.UserAccountModule) },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
