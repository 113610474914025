import { Injectable, InjectionToken, Inject } from "@angular/core";
import {
  HttpInterceptor,
  HttpEvent,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
  HttpResponse,
} from "@angular/common/http";
import { Observable, throwError, empty, TimeoutError } from "rxjs";
import { timeout, catchError } from "rxjs/operators";
import { ToastrService } from "ngx-toastr";
import { environment } from "src/environments/environment";
// import { SimpleCrypt } from "ngx-simple-crypt";
import { map, filter } from "rxjs/operators";
import { Router, ActivatedRoute } from "@angular/router";
export const DEFAULT_TIMEOUT = new InjectionToken<number>("defaultTimeout");

@Injectable({
  providedIn: "root",
})
export class HttpInterceptorService implements HttpInterceptor {
  public token: any;
  tokenIntervel:any
  constructor(
    @Inject(DEFAULT_TIMEOUT) protected defaultTimeout: number,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private router: Router
  ) {} // private toastr: ToastrService

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const modified = req.clone({
      setHeaders: { "X-Request-Timeout": `${this.defaultTimeout}` },
    });

    if (req.method == "POST") {
      if (Object.keys(req.body).length == 0) {
        // console.log(Object.keys(req.body).length)
        // console.log("formdata")
        // check if it formdata or json
        var object = {};
        req.body.forEach(function (value, key) {
          object[key] = value;
        });
        var json = object;
        let errorFormFound = 0;

        for (const [key, value] of Object.entries(json)) {
          //  // console.log(`${value}`)
          if (
            `${value}`.includes("<") ||
            `${value}`.includes(">") ||
            `${value}`.includes("!") ||
            `${value}`.includes("||") ||
            `${value}`.includes("%") ||
            `${value}`.includes("*") ||
            `${value}`.includes("'") ||
            `${value}`.includes("=") ||
            `${value}`.includes('"') ||
            `${value}`.includes("\\")
          ) {
            errorFormFound = 1;
          }
        }
        if (errorFormFound == 1) {
          this.toastr.error("Cant include special carecters");
        } else {
          return this.afterCheckBody(req, next);
        }
      } else {
        let errorJsonFound = 0;

        for (const [key, value] of Object.entries(req.body)) {
          // console.log("testttttt",`${value}`)

          if (
            `${value}`.includes("<") ||
            `${value}`.includes(">") ||
            `${value}`.includes("!") ||
            `${value}`.includes("||") ||
            `${value}`.includes("%") ||
            `${value}`.includes("*") ||
            `${value}`.includes("'") ||
            `${value}`.includes("=") ||
            `${value}`.includes('"') ||
            `${value}`.includes("\\")
          ) {
            errorJsonFound = 1;
          }
        }
        if (errorJsonFound == 1) {
          this.toastr.error("Cant include special carecters");
        } else {
          return this.afterCheckBody(req, next);
        }
      }
    } else {
      return this.afterCheckBody(req, next);
    }

    return next.handle(modified).pipe(
      timeout(this.defaultTimeout),
      catchError((err) => {
        if (err instanceof TimeoutError)
          console.error("Timeout has occurred ddddd", req.url);
        return empty();
      })
    );
  }


   isLogoutApi(url: string): boolean {
    // Add conditions here to identify the logout API endpoint
    // For example:
    return url.includes('logout');
    // return false; 
    // Replace this with the actual condition
  }

  afterCheckBody(req: any, next: any) {
    // console.log(sessionStorage.getItem("simulationMode"),"123123123")
    // console.log(("successsss"));
    var lang = localStorage.getItem("lang");
    if (sessionStorage.getItem("token") == null) {
      
      let curl = sessionStorage.getItem("simulationMode")== "true"? environment.simulationApiUrl:environment.apiUrl || this.getHostName();
      req = req.clone({
        url: `${curl}/${req.url}`,
        setHeaders: {
          lang: localStorage.getItem("lang"),
        },
      });
    } else {
      const tokenLav: any = sessionStorage.getItem("tokenLav");
      const token: any = sessionStorage.getItem("token");
      let curl = sessionStorage.getItem("simulationMode") == "true"? environment.simulationApiUrl:environment.apiUrl || this.getHostName();

      req = req.clone({
        url: `${curl}/${req.url}`,
        setHeaders: {
          Authorization: `Bearer ` + tokenLav,
          
          lang: localStorage.getItem("lang"),

          'Authorization-j':  token,

        },
      });

      // refreshtoken

      var refreshToken = sessionStorage.getItem("refreshToken");
      var timestamp: any = sessionStorage.getItem("time");

      // console.log("timestamp", timestamp);
      // console.log("timestamp22", new Date(timestamp).getTime());
      let countDown = new Date(timestamp).getTime();
      // Get today's date and time

      var now = new Date().getTime();
      // console.log("noww==>", now);
      // Find the distance between now and the count down date

      var distance = countDown - now;

      // Time calculations for days, hours, minutes and seconds

      var days = Math.floor(distance / (1000 * 60 * 60 * 24));

      var hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );

      var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));

      var seconds = Math.floor((distance % (1000 * 60)) / 1000);

      // Output the result in an element with id="demo"

      // console.log(
      //   "cheeeeck",
      //   days + "d " + hours + "h " + minutes + "m " + seconds + "s "
      // );
      if (days == 0 && hours == 0 && minutes <= 2) {
        if (!this.isLogoutApi(req.url)){
          let body = {
            refreshToken: refreshToken,
          };
          const token: any = sessionStorage.getItem("token");
          const tokenLav: any = sessionStorage.getItem("tokenLav");

          var headers = new Headers();
          headers.set("Accept", "application/json");
          headers.set("Content-type", "application/json");
          headers.set("Authorization", `Bearer ` + tokenLav);
          headers.set("Authorization-j", token);

          const response = fetch(`${(sessionStorage.getItem("simulationMode")== "true"? environment.simulationApiUrl:environment.apiUrl)}/user/refreshToken`, {
            body: JSON.stringify(body),
            method: "POST",
            headers: headers,
          })
            .then((response) => response.json())
            .then((responsData) => {
              // console.log("interseptoooooooooorrrrrrrrrrrr")
  
              // console.log("responsData");
  
              // console.log(responsData);
              let res: any = responsData;
              sessionStorage.setItem(
                "refreshToken",
                res.response.credentials.token.refreshTokenString
              );
              sessionStorage.setItem(
                "time",
                res.response.credentials.token.tokenExpiry
              );
              sessionStorage.setItem(
                "token",
                res.response.credentials.token.tokenString
              );
            });
        }
        
      }
      // refreshtoken
    }

    // refreshtoken
    // if (sessionStorage.getItem("time") != null) {
    // }
    // var x ;
    // var intervalIsActive = true; // Flag to indicate if interval should be active
    clearInterval(this.tokenIntervel)
     this.tokenIntervel = setInterval(function () {

      // if (!intervalIsActive) {
      //   return; // Exit the interval function if the flag is not active
      // }
    
      var refreshToken = sessionStorage.getItem("refreshToken");
      var timestamp: any = sessionStorage.getItem("time");
      // console.log("timestamp", timestamp);
      // console.log("timestamp22", new Date(timestamp).getTime());
      let countDown = new Date(timestamp).getTime();
      // Get today's date and time

      var now = new Date().getTime();
      // console.log("noww==>", now);
      // Find the distance between now and the count down date

      var distance = countDown - now;

      // Time calculations for days, hours, minutes and seconds

      var days = Math.floor(distance / (1000 * 60 * 60 * 24));

      var hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );

      var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));

      var seconds = Math.floor((distance % (1000 * 60)) / 1000);

      // Output the result in an element with id="demo"
      // console.log(
      //   "====>",
      //   days + "d " + hours + "h " + minutes + "m " + seconds + "s "
      // );
      // console.log("MMMMMMMMMMMMMMMMM")

      // If the count down is over, write some text
      if (days == 0 && hours == 0 && minutes == 0 && seconds == 59) {
        // alert("Do you want to exit")
        // console.log("LLLLLLLLLLLLLLLLLLLLLLLLLLLLLL")
        // intervalIsActive = false;
        document.getElementById("checkInter")!.click();
        clearInterval(this.tokenIntervel);
        console.log("HEEEER",days ,hours, minutes , seconds);
        return
        // const token: any = sessionStorage.getItem("token");
        // let body = {
        //   refreshToken: refreshToken,
        // };
        // var headers = new Headers();
        // headers.set("Accept", "application/json");
        // headers.set("Content-type", "application/json");
        // headers.set("Authorization", `Bearer ` + token);
        // const response = fetch(
        //   `https://demolinuxwar.azurewebsites.net/api/refresh`,
        //   {
        //     body: JSON.stringify(body),
        //     method: "POST",
        //     headers: headers,
        //   }
        // )
        //   .then((response) => response.json())
        //   .then((responsData) => {
        //     // console.log("responsData");

        //     // console.log(responsData);
        //     let res: any = responsData;
        //     sessionStorage.setItem(
        //       "refreshToken",
        //       res.response.credentials.token.refreshTokenString
        //     );
        //     sessionStorage.setItem(
        //       "time",
        //       res.response.credentials.token.tokenExpiry
        //     );
        //     sessionStorage.setItem(
        //       "token",
        //       res.response.credentials.token.tokenString
        //     );
        //   });
      }
      if (distance < 0 && timestamp == null) {
        // intervalIsActive = false;
        clearInterval(this.tokenIntervel);
        // console.log("====>", "EXPIRED");
      }if (distance < 0 && timestamp != null){
        // intervalIsActive = false;
        document.getElementById("hiddenLogOutBtn")!.click();
        clearInterval(this.tokenIntervel);
        // console.log("====>", "last");
      }
    }, 1000);
    // refreshtoken

    if (navigator.onLine) {
      localStorage.removeItem("noConnection");
    } else {
      localStorage.setItem("noConnection", "true");
      throw {
        errors: "No internet connection, please try again!",
        code: 503,
      };
    }
    return next.handle(req).pipe(
      timeout(this.defaultTimeout),
      catchError((error: HttpErrorResponse) => {
        if (navigator.onLine) {
          localStorage.removeItem("noConnection");
          if (error.status == 401) {
            location.reload();
            localStorage.clear();
            window.open("/", "_Self");
          }
          // console.log(error);
          if (error.name != undefined && error.status == undefined) {
            this.toastr.error(error.message);
          }
          if (error.status == 400) {
            this.toastr.error(error.error.message);
            // throw {
            //   errors: error,
            // };
          }
          if (error.status == 404) {
            this.router.navigate(["/page-not-found"]);
            // throw {
            //   errors: error,
            // };
          }
        } else {
          localStorage.setItem("noConnection", "true");
          throw {
            errors: "No internet connection, please try again!",
            code: 503,
          };
        }

        return throwError(error);
      })
    );
  }
  getHostName() {
    return window.location.protocol + "//" + window.location.host;
  }
}
