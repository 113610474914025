import { Component, OnInit, Renderer2 } from "@angular/core";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  title = "Naeem";
  
  useCustomColors: any = JSON.parse(sessionStorage.getItem("simulationMode"))// set locale storage here

  constructor(private renderer: Renderer2) {}

  ngOnInit(): void {
    // Set CSS variables
    this.renderer.setStyle(
      document.documentElement,
      "--use-custom-colors",
      this.useCustomColors ? "true" : "false"
    );
    // console.log(sessionStorage.getItem("simulationMode"),"PLPLPLPLPL")
    // console.log(this.useCustomColors,"eeeeeeee")

  }

  
  onActivate(event: any) {
    window.scroll(0, 0);
    document.body.scrollTop = 0;
    //or document.querySelector('body').scrollTo(0,0)
  }
}
